$(document).ready(function () {
    // $(".swap-title").click(function (e) {
    //     e.preventDefault();
    //     $(".swap-circle").removeClass("active");
    //     $(this).parent().addClass("active");
    //     $(".application-features .one-block.show").removeClass("show");
    //     var currentSwapId = $(this)
    //         .parent()
    //         .attr("class")
    //         .replace("swap-circle ", "")
    //         .replace(" active", "");
    //     $(document.getElementById(currentSwapId)).addClass("show");
    // });
    // $("#close-menu").click(closeMenu);
    // $(".menu-bg").click(closeMenu);
    // $("#open-menu").click(openMenu);
    // function closeMenu(e) {
    //     e.preventDefault();
    //     $(".main-menu").addClass("close");
    //     $(".menu-bg").addClass("d-none");
    // }
    // function openMenu(e) {
    //     e.preventDefault();
    //     $(".main-menu").removeClass("close");
    //     $(".menu-bg").removeClass("d-none");
    // }
});

$(window).scroll(function () {
    mainNav(120, 50);
});

$(window).on("load", function () {
    mainNav(120, 50);

    /**
     * Remove loader after website load
     */
    $(".loader, .loader .loader-data").fadeOut(2000);

    randomTechStackImages(false);
});

/**
 * fixed navbar
 */
function mainNav(scroll_max = 120, scroll_min = 50) {
    var scroll = $(window).scrollTop();

    if (scroll > scroll_max) {
        $("#mainNav").addClass("fixed-top");
    }
    if (scroll < scroll_min) {
        $("#mainNav").removeClass("fixed-top");
    }
}

/**
 * select Random Tech Stack Images
 */
function randomTechStackImages(status = true, timeout = 2000) {
    var items = $("#tech-stack-images div img");

    if (status) {
        items.removeClass("active");

        for (var i = 1; i <= 8; i++) {
            var random = Math.floor(Math.random() * items.length);
            var selectedItem = items.eq(random);

            selectedItem.addClass("active");
        }

        setTimeout(randomTechStackImages, timeout);
    }
    else {
        items.addClass("active");
    }
}

// setTimeout(function () {
//   $(".loader").fadeOut(2000);
// }, 2000);

AOS.init();
